#root .sidebar-divider {
  height: 1px;
  width: 100%;
  background: #455a64;
  border: none;
  margin: 12px 0px;
}

#root .sidebar-divider.light {
  background: #455a64;
  min-width: 235px;
}

#root .sidebar-divider.dark {
  background: #1c313a;
  min-width: 235px;
}

#root a,
#root button {
  border-radius: 50;
}

#root .sidebar-dark [data-testid='sidebar-root'] > div {
  background: #000a12;
}

#root .logo-full {
  width: 75px;
  position: relative;
  left: -10px;
}

.sidebar-title {
  display: flex;
  align-items: center;
}

.sidebar-title h2 {
  font-size: 30px;
  font-weight: bold;
  margin: -15px;
  color: #f49f0a;
}

#root .logo-icon {
  width: 75px;
  position: relative;
  left: -25px;
}

#root .create-template-card h3,
#root .create-template-card h4 {
  color: #fff;
}

/* for Dark mode */
#root .homepage-bg:before {
  /* background-image: url('../../../public/img/cloud9-new-DT-logo.png'); */
  /* packages\app\public\img\cloud9-new-DT-logo.png */
  background-repeat: no-repeat;
  content: ' ';
  width: 1030px;
  height: 600px;
  display: block;
  position: relative;
  top: -133px;
  opacity: 1;
  margin: 0 auto -600px;
  transform: scale(0.5);
}

#root .homepage-search {
  position: relative;
}

#root .homepage-bg--light:before {
  /* background-image: url('../../../public/img/cloud9-new-DT-logo.png'); */
  opacity: 1;
}

#___reactour .reactour__helper {
  color: black;
}

iframe {
  overflow-clip-margin: content-box !important;
  overflow: clip !important;
  border-width: 0px;
  border-style: inset;
  border-color: initial;
  border-image: initial;
  width: '100%';
}

/*
#root a:focus,
#root button:focus,
#root input:focus,
#root div[role='button']:focus {
    outline: 3px solid white;
}

#root .homepage-bg--light a:focus,
#root .homepage-bg--light button:focus,
#root .homepage-bg--light input:focus/*,
#root .homepage-bg--light div[role='button']:focus {
    outline: 3px solid #31338a;
}*/
/*
.homepage-report-style-class {
  height: 100vh;
  margin: 0% auto;
  width: 100%;
}

.entity-report-style-class {
  height: 99vh;
  margin: 0% auto;
}

.homepage-report-style-class {
  height: 40vh;
  margin: 0% auto;
  width: 105%;
}

iframe {
  border: none;
  height: 100vh;
}

.carbonfootprint-report-style-class {
  height: 100%;
  margin: 0% auto;
  width: 100%;
}

.carbonfootprint-emissionovertime-report-style-class {
  height: 100%;
  margin: 0% auto;
  width: 100%;
}

.costinsights-report-style-class {
  height: 650px;
  margin: 0% auto;
  width: 100%;
}

.costinsights-alert-report-style-class {
  height: 750px;
  margin: 0% auto;
 
}
*/
